<template>
  <div class="success_box">
    <div class="success_banner">
          <video
            autoplay="autoplay"
            muted
            loop
            :src="bannerData.bannerUrl"
            v-if="bannerData.type == 2"
          ></video>

          <el-image
            class="sucimg"
            :src="bannerData.bannerUrl"
            fit="cover"
            v-if="bannerData.type == 1"
          />
    </div>
    <div class="suc_box">
      <div class="use_title" v-animation:home="'a-fadeinT'">成功案例</div>
      <div class="changeys">
        <div
            :class="{ cz_left: true}"
            @click="jian()"
          >
            <el-image
              class="czimg"
              :src="require('../assets/left.png')"
              fit="cover"
            ></el-image>
          </div>
        <div
          :class="{
            sucmain_box: true,
            'a-bounceinL': jianc3,
            'a-bounceinR': jiac3,
          }"
        >
          <div class="cgalMain" v-for="(item,index) in successfulCasesData" :key="index">
            <div class="imgbox" > <!--@click="openbigimg1(item,index)"-->
              <el-image
                class="cgalImage"
                :src="item.imgUrl"
                fit="cover"
              >
              </el-image>
            </div>

            <div class="cgalZT">
              <!-- <div class="cgalBT">二口机</div> -->
              <div class="cgalMs">
                这是打掉技术等级阿斯的旧爱就嗲就搜到捷爱士京东埃及的加多描述
              </div>
            </div>
          </div>
        </div>
        <div class="cz_left" @click="jia()">
            <el-image
              class="czimg"
              :src="require('../assets/you.png')"
              fit="cover"
            ></el-image>
          </div>
      </div>

      <!-- <div class="cz_box">
        <div class="cz_tiao">
          <div
            :class="{ cz_left: true, 'hvr-sweep-to-left': true }"
            @click="jian()"
          >
            <el-image
              class="czimg"
              :src="require('../assets/left.png')"
              fit="cover"
            ></el-image>
          </div>
          <div class="cz_center">{{ index }}/{{ total }}</div>
          <div class="cz_left hvr-sweep-to-right" @click="jia()">
            <el-image
              class="czimg"
              :src="require('../assets/you.png')"
              fit="cover"
            ></el-image>
          </div>
        </div>
      </div> -->
    </div>
    <div class="openbigimg" @click="closeimg()" v-show="openbigimg">
      <transition name="el-fade-in-linear">
        <el-image
          v-if="successfulCasesData.length>0"
          class="openimg"
          :src="successfulCasesData[idx].imgUrl"
          fit="cover"
        ></el-image>
      </transition>
    </div>
    <div class="button_gg">
      <HelloWorld></HelloWorld>
    </div>
  </div>
</template>
<script>
import HelloWorld from "../components/HelloWorld";
import { banner,successfulCases } from "@/api/api_list";
export default {
  data() {
    return {
      jianc3: false,
      jiac3: false,
      index: 1,
      total: 5,
      openbigimg: false,
      bannerData:[],
      successfulCasesData:[],
      idx:0
    };
  },
  components: {
    HelloWorld,
  },
  created(){
    banner({ location: 4 }).then((res) => {
      if (res.code == 200) {
        this.bannerData = res.data;
      }
    });
    this.list()
  },
  methods: {
    list(){
      successfulCases({ pageNum:this.index,pageSize:5 }).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.successfulCasesData = res.data.list;
          this.total = res.data.list.length;
        }
      });
    },
    jian() {
      let a = this.index - 1;
      console.log(a, this.total);
      if (a > this.total) {
        console.log("进来了");
        this.index = a;
        this.jianc3 = true;
        this.jiac3 = false;
        setTimeout(() => {
          this.jianc3 = false;
          this.jiac3 = false;
        }, 500);
      } else {
        if (this.index != 1) {
          this.index = this.index - 1;
          console.log("进来了");
          this.list()
          this.jianc3 = true;
          this.jiac3 = false;
          setTimeout(() => {
            this.jianc3 = false;
            this.jiac3 = false;
          }, 500);
        }
      }
    },
    jia() {
      let q = this.index + 1;
      if (q <= this.total) {
        this.index = q;
        this.jianc3 = false;
        this.jiac3 = true;
        setTimeout(() => {
          this.jianc3 = false;
          this.jiac3 = false;
        }, 500);
        this.list()
      } else {
        // this.index = 5;
      }
    },
    openbigimg1(item,index) {
      this.openbigimg = true;
      this.idx = index
    },
    closeimg() {
      this.openbigimg = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.success_box {
  width: 100%;
  height: 100%;
  background: black;
  .success_banner {
    width: 100%;
    height: 400px;
    .sucimg {
      width: 100%;
      height: 100%;
    }
  }
  .suc_box {
    width: 100%;
    background: black;
    .use_title {
      width: 100%;
      height: 50px;
      font-size: 26px;
      font-weight: bold;
      color: #fff;
      line-height: 50px;
      text-align: center;
      padding-top: 50px;
      margin-bottom: 50px;
    }
    .changeys{
      width: 100%;
      /* background: chocolate; */
      display: flex;
      justify-content: center;
      align-items: center;
      .cz_left{
        width: 50px;
        height: 50px;
      }
      .sucmain_box {
      margin: 0 auto;
      width: 80%;
      height: 400px;
      overflow-x: auto;
      display: flex;
      .cgalMain {
        width: 19%;
        margin-right: 1%;
        height: 400px;
        background: #101010;
        overflow: hidden;
        .imgbox {
          width: 100%;
          height: 300px;
          /* background: aquamarine; */
          overflow: hidden;
          .cgalImage {
            width: 100%;
            height: 300px;
            /* background: aquamarine; */
            transition: all 0.6s;
          }
          .cgalImage:hover {
            transform: scale(1.1);
          }
        }
        .cgalZT {
          width: 100%;
          height: 100px;
          .cgalMs {
            /* margin-top: 20%; */
            /* padding: 20px 10px; */
            margin: 0 auto;
            margin-top: 20px;
            box-sizing: border-box;
            width: 80%;
            /* height: 100px; */
            line-height: 20px;
            text-align: left;
            color: #606060;
            font-size: 12px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            /* margin-top: 20px; */
          }
        }
      }
    }
    }

    .cz_box {
      margin-top: 50px;
      width: 100%;
      height: 60px;
      .cz_tiao {
        margin: 0 auto;
        width: 400px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cz_left {
          width: 60px;
          height: 60px;
          cursor: pointer;
          .czimg {
            width: 100%;
            height: 100%;
          }
        }
        .cz_center {
          font-size: 20px;
          color: #fff;
        }
      }
    }
  }
  .button_gg {
    width: 100%;
    height: 100%;
    background: black;
  }
  .openbigimg {
    width: 80%;
    height: 80%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    .openimg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
